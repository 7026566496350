import React from "react" 
import styled from "styled-components";

// Section Component Imports
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/Footer";

// Components
import {HeadingOne} from "./../components/Headings";
import Skepta from "./../components/wrappers/Skepta";

import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="404: Not Found"/>
    <Navigation/>
    <SectionContainer>
        <Skepta width={`50%`}>
            <HeadingOne>404: Page not found</HeadingOne>
        </Skepta>
    </SectionContainer>
    <Footer/>
  </>
)

const SectionContainer = styled.section`
    background-color: #F4F4F0;
    min-height: calc(100%);
    width: 100%;
    padding: 7.5% 0 5% 0;
`;

export default IndexPage
